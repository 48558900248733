<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!--
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
      </b-link>
      -->
      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-media class="mb-3">
            <template #aside>
              <b-img
                :src="require('@/assets/images/logo/snu_logo.jpg')"
                blank-color="#ccc"
                width="110"
                alt="placeholder"
              ></b-img>
            </template>
            <div class="text-center">
              <h2
                class="font-weight-bolder"
                style="letter-spacing: 8px; margin-top: 21px"
              >
                AMPFRI
              </h2>
              <p
                class="h6 mt-1"
                style="letter-spacing: 4px"
              >
                식품 및 외식산업 보건<br />최고 경영자 과정
              </p>
            </div>
          </b-media>

          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h4"
          >
            동문 / 임원진 여러분 환영합니다.
          </b-card-title>
          <b-card-text class="mb-2"
            >서비스 이용을 위해 로그인이 필요합니다.</b-card-text
          >
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="아이디"
                label-for="login-userid"
              >
                <validation-provider
                  #default="{ errors }"
                  name="userId"
                  rules="required"
                >
                  <b-form-input
                    id="login-userid"
                    v-model="userId"
                    :state="errors.length > 0 ? false : null"
                    name="login-userid"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">비밀번호</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>비밀번호 찾기</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    autocomplete="off"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  로그인 정보 저장
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                로그인
              </b-button>
            </b-form>
          </validation-observer>
          <div class="text-center mt-4">
            <p>계정 재설정 및 앱 문의</p>
            <p>기획위원 최두호 <a href="tel:010-9184-4933">010-9184-4933</a></p>
            <p>
              <a href="mainto:devteam@topint.co.kr">devteam@topint.co.kr</a>
            </p>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import VuexyLogo from '@core/layouts/components/Logo.vue';
  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VBTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
  } from 'bootstrap-vue';
  import useJwt from '@/auth/jwt/useJwt';
  import { required } from '@validations';
  import { togglePasswordVisibility } from '@core/mixins/ui/forms';
  import store from '@/store/index';
  import { getHomeRouteForLoggedInUser } from '@/auth/utils';

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

  import { $themeConfig } from '@themeConfig';

  export default {
    directives: {
      'b-tooltip': VBTooltip,
    },
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      BAlert,
      VuexyLogo,
      ValidationProvider,
      ValidationObserver,
      BMedia,
      BMediaAside,
      BMediaBody,
    },
    mixins: [togglePasswordVisibility],
    data() {
      const { appName, appLogoImage } = $themeConfig.app;

      return {
        status: '',
        password: '',
        userId: '',
        sideImg: require('@/assets/images/pages/login-v2.svg'),

        // validation rules
        required,
        // appThemeConfig
        appName,
        appLogoImage,
      };
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
          return this.sideImg;
        }
        return this.sideImg;
      },
    },
    methods: {
      login() {
        this.$refs.loginForm.validate().then(success => {
          if (success) {
            useJwt
              .login({
                branchId: 3,
                userId: this.userId,
                password: this.password,
              })
              .then(response => {
                // console.log(response.data);
                const { userData } = response.data;
                useJwt.setToken(response.data.accessToken);
                useJwt.setRefreshToken(response.data.refreshToken);
                localStorage.setItem('userData', JSON.stringify(userData));
                this.$ability.update(userData.ability);

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                // console.log(userData.role);
                this.$router
                  .replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-center',
                      props: {
                        title: `환영합니다. ${userData.userName} 님`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `로그인에 성공했습니다.`,
                      },
                    });
                  });
              })
              .catch(error => {
                // console.log('error', error);
                this.$refs.loginForm.setErrors(error.response.data.error);
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>
  /* .media-aside {
    margin-right: 0.2rem;
  } */
</style>
